.AppHeader {
  text-align: right;
}

.myContainer {
  padding: 16px 32px;
}

.modal {
  max-width: 100vw;
}
